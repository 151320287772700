class CssModifier {
    /**
     * Check if has css class.
     *
     * @param element
     * @param className
     */
    public hasClass(element: any, className: string): boolean {
        return new RegExp(' ' + className + ' ').test(' ' + element.className + ' ');
    }

    /**
     * Add css class.
     *
     * @param element
     * @param className
     */
    public addClass(element: any, className: string): void {
        if (!this.hasClass(element, className)) {
            element.className += ' ' + className;
        }
    }

    /**
     * Remove css class.
     *
     * @param element
     * @param className
     */
    public removeClass(element: any, className: string) {
        let newClass: string = ' ' + element.className.replace(/[\t\r\n]/g, ' ') + ' ';
        if (this.hasClass(element, className)) {
            while (newClass.indexOf(' ' + className + ' ') >= 0) {
                newClass = newClass.replace(' ' + className + ' ', ' ');
            }
            element.className = newClass.replace(/^\s+|\s+$/g, '');
        }
    }
}

export default new CssModifier();
