import React from "react";
import {Button, Modal} from "react-bootstrap";
import "./Modal.scss";

function getFooterFragment(onHide: any) {
    return <>
        <Button onClick={onHide}>Fermer</Button>
    </>;
}

const CustomModal: React.FC<{ show: boolean, onHide: any, title: string, body: React.ReactFragment, footer: React.ReactFragment | null}> = props => (
    <>
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>{null === props.footer && getFooterFragment(props.onHide)}</Modal.Footer>
        </Modal>
    </>
);

export default CustomModal;
