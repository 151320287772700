import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import IDatas from "../../interfaces/IDatas";
import DatasAPIs from "../../APIs/DatasAPIs";
import CustomModal from "./CustomModal";

const DatasCommentModal: React.FC<{ index: number, datas: IDatas, datasList: IDatas[], setDatasList: Function, show: boolean, onHide: any }> = props => {
    interface InputForm {
        comment?: string
    }

    const [validated, setValidated] = useState<boolean>(false);
    const [inputs, setInputs] = useState<InputForm>({});

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        const form = event.currentTarget;
        const isValidForm = form.checkValidity();

        event.preventDefault();
        if (!isValidForm) {
            event.stopPropagation();
        }

        if (isValidForm) {
            DatasAPIs.postDatasAction(props.datas.id, inputs)
                .then(res => {
                    props.datas.actions = props.datas.actions ? [...props.datas.actions, res.data] : [res.data];

                    let newDatasList = [...props.datasList];
                    newDatasList[props.index] = props.datas;

                    props.setDatasList(newDatasList);

                    props.onHide();
                });
        }

        setValidated(true);
    };

    function getBodyFragment() {
        return <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="FormComment.ControlTextareaComment">
                    <Form.Control required as="textarea" rows="5" onChange={handleInputChange} name="comment" value={inputs.comment}/>
                    {/*<Form.Control.Feedback type="invalid">Veuillez saisir un commentaire.</Form.Control.Feedback>*/}
                </Form.Group>
                <Button type="submit">Ajouter</Button>
            </Form>
        </>;
    }

    return (
        <>
            <CustomModal
                show={props.show}
                onHide={props.onHide}
                title="Fiche client"
                body={getBodyFragment()}
                footer={null}
            />
        </>
    );
};

export default DatasCommentModal;
