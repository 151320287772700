import React from "react";
import "./Footer.scss";

const Footer: React.FC = () => (
    <footer className="footer">
        <span className="icon-logoLBI icon"></span>
        <p className="copyright">© 2020 | Tous droits réservés</p>
    </footer>
);

export default Footer;
