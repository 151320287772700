import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Redirect} from 'react-router'
import TokenStorage from "../helpers/TokenStorage";
import Header from "./header/Header";
import Home from "./home/Home";
import Login from "./login/Login";
import Footer from "./footer/Footer";
import Lists from "./list/Lists";
import SiteList from "./site/SiteList";
import DemandList from "./demand/DemandList";
import WorkList from "./work/WorkList";
import DatasList from "./datas/DatasList";
import SiteService from "../services/SiteService";
import WorkService from "../services/WorkService";
import DatasService from "../services/DatasService";
import IUser from "../interfaces/IUser";
import UserStorage from "../helpers/UserStorage";
import UserAPIs from "../APIs/UserAPIs";
import {AxiosResponse} from "axios";
import CssModifier from "../helpers/CssModifier";

const App: React.FC = () => {
    const [className, setClassName] = useState<string>('App noSectionMenu');
    const [user, setUser] = useState<IUser | null>(UserStorage.getAuthenticatedUser());
    const isFirst = useRef<boolean>(true);
    const isAuthenticated = TokenStorage.isAuthenticated();

    useEffect(() => {
        if (!(isAuthenticated && isFirst.current && null === user)) {
            return;
        }

        isFirst.current = false;

        UserAPIs.getAuthenticatedUser()
            .then((response: AxiosResponse) => {
                const user: IUser = response.data;

                setUser(user);
                UserStorage.storeAuthenticatedUser(user);
            });
    }, [isAuthenticated, isFirst, user]);

    const handleHeaderLeave = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
        CssModifier.removeClass(event.currentTarget, 'active');
    };

    const handleHeaderClick = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
        const parentChild: ParentNode | null = event.currentTarget.parentNode && event.currentTarget.parentNode.parentNode;

        if (CssModifier.hasClass(parentChild, 'active')) {
            CssModifier.removeClass(parentChild, 'active');
        } else {
            CssModifier.addClass(parentChild, 'active');
        }
    };

    return (
        <div className={className}>
            {isAuthenticated ?
                <Router>
                    <div className="sectionMenu transition250" onMouseLeave={handleHeaderLeave}>
                        <div className="burgerContainer">
                            <span className="burger" onClick={handleHeaderClick}/>
                            <span className="sectionEnter" onMouseEnter={handleHeaderClick}/>
                        </div>
                        <div className="sectionMenuContainer">
                            <Header user={user}/>
                            <Footer/>
                        </div>
                    </div>
                    <main className="sectionPage">
                        <Switch>
                            <Route exact path="/" render={() => <Home setAppClassName={setClassName}/>}/>
                            <Route exact path="/demand-lists" render={() => <DemandList setAppClassName={setClassName}/>}/>
                            <Route exact path="/site-lists" render={() => <Lists key="site"  type="site" listComponent={SiteList} setAppClassName={setClassName} initialTab="inProduction" tabs={SiteService.SITE_TABS}/>}/>
                            <Route exact path="/work-lists" render={() => <Lists key="work"  type="work" listComponent={WorkList} setAppClassName={setClassName} initialTab="toQualify" tabs={WorkService.WORK_TABS}/>}/>
                            <Route exact path="/datas-lists" render={() => <Lists key="datas" type="datas" listComponent={DatasList} setAppClassName={setClassName} initialTab="toObtain" tabs={DatasService.DATAS_TABS}/>}/>
                            <Route exact path="/logout"/>
                        </Switch>
                    </main>
                </Router>
                :
                <Router>
                    <main className="sectionPage">
                        <Switch>
                            <Route exact path="/login" component={Login}/>
                            <Redirect to="/login"/>
                        </Switch>
                    </main>
                </Router>
            }
        </div>
    );
};

export default App;
