import IUser from "../interfaces/IUser";
import IDatasAction from "../interfaces/IDatasAction";
import AppService from "./AppService";

/**
 * Class DatasService.
 */
class DatasService extends AppService {
    public DATAS_TABS = [
        {key: 'toObtain', label: 'A récupérer'},
        {key: 'toInstall', label: 'A installer'},
        {key: 'toVerify', label: 'A vérifier'},
        {key: 'finished', label: 'Terminé'},
        {key: 'inError', label: 'Retour client'},
    ];

    public DATAS_LIST_HEADERS = {
        toObtain: ['Date de création', 'Projet', 'Type', 'Prestataire', 'Status', 'Produit associé', 'Commentaires', 'Chef de projet', 'Actions'],
        toInstall: ['Date de création', 'Projet', 'Type', 'Prestataire', 'Status', 'Produit associé', 'Commentaires', 'Date de planification', 'Chef de projet', 'Opérateur', 'Actions'],
        toVerify: ['Date de création', 'Projet', 'Type', 'Prestataire', 'Status', 'Produit associé', 'Commentaires', 'Date d\'intégration', 'Chef de projet', 'Opérateur', 'Actions'],
        finished: ['Date de création', 'Projet', 'Type', 'Prestataire', 'Status', 'Produit associé', 'Commentaires', 'Date de clôture', 'Chef de projet', 'Opérateur', 'Actions'],
        inError: ['Date de création', 'Projet', 'Type', 'Prestataire', 'Status', 'Produit associé', 'Chef de projet', 'Opérateur', 'N° de tâche', 'Commentaires', 'Attribué à', 'Actions'],
    };

    public DATAS_ACTIONS_PER_TAB = {
        toObtain: ['addCommentary', 'nextStatus'],
        toInstall: ['addCommentary', 'clientProfile', 'previousStatus', 'nextStatus', 'successStep', 'errorStep'],
        toVerify: ['addCommentary', 'clientProfile', 'previousStatus', 'nextStatus', 'successStep', 'errorStep'],
        finished: ['addCommentary', 'previousStatus', 'nextStatus'],
        inError: ['addCommentary'],
    };

    /**
     * Get header for specific tabs.
     *
     * @param datasTabs
     */
    public getHeaderList(datasTabs: any): [] {
        return DatasService.getProperty(this.DATAS_LIST_HEADERS, datasTabs);
    }

    /**
     * Get actions per tab.
     *
     * @param datasTabs
     */
    public getActionsPerTab(datasTabs: any): [] {
        return DatasService.getProperty(this.DATAS_ACTIONS_PER_TAB, datasTabs);
    }

    /**
     * Get full name from user.
     *
     * @param user
     */
    public getUserFullName(user: IUser): string {
        return user.nom + ' ' + user.prenom;
    }

    public getLastRelaunchAt(actions: IDatasAction[]): string {
        if (0 !== actions.length) {
            const action = actions[actions.length - 1];

            return action.relance;
        }

        return '';
    }
}

export default new DatasService();


