import Api from "../helpers/Api";
import TokenStorage from "../helpers/TokenStorage";
import UserStorage from "../helpers/UserStorage";

/**
 * LoginAPIs class.
 */
export default class LoginAPIs {
    /**
     * Login.
     */
    public static login(username: string, password: string) {
        const data = {
            username: username,
            password: password,
        };

        return Api.post(`login_check`, data);
    }

    /**
     * Logout.
     */
    public static logout() {
        TokenStorage.clear();
        UserStorage.clear();
        window.location.href = '/login';
    }
}
