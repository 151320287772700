import React, {useState} from "react";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import LoginAPIs from "../../APIs/LoginAPIs";
import TokenStorage from "../../helpers/TokenStorage";
import {AxiosResponse} from "axios";
import "./Login.scss";
import logo from '../../img/logo.svg';

const initialState = {
    email: "",
    password: ""
};

const userSchema = Yup.object().shape({
    email: Yup
        .string()
        .email('Veuillez renseigner un email valide')
        .required('L\'email est obligatoire'),
    password: Yup
        .string()
        .required('Le password est obligatoire')
});

const Login: React.FC = () => {
    const [user, setUser] = useState<object>(initialState);

    return (
        <React.Fragment>
            <Formik
                initialValues={user}
                onSubmit={(values: any, actions: any) => {
                    actions.setSubmitting(true);
                    setUser(values);

                    LoginAPIs.login(values.email, values.password)
                        .then((response: AxiosResponse) => {
                            TokenStorage.storeToken(response.data.token);
                            TokenStorage.storeRefreshToken(response.data.refresh_token);

                            window.location.href = '/';
                        })
                }}

                validationSchema={userSchema}
            >
                {(props: any) =>
                    <div className="loginContainer">
                        <div className="formLoginStructure">
                            <div className="formLogin">
                                <img src={logo} alt="logo alfred" className="logo"/>
                                <form onSubmit={props.handleSubmit} className="formContainer">
                                    <Field type="email" placeholder="Email" onChange={props.handleChange} name="email" value={props.values.email} className="inputLogin"/>
                                    {props.errors.email && props.touched.email ? (<span className="errorLogin">{props.errors.email}</span>) : ("")}

                                    <Field type="password" placeholder="Password" onChange={props.handleChange} name="password" value={props.values.password} className="inputLogin"/>
                                    {props.errors.password && props.touched.password ? (<span className="errorLogin">{props.errors.password}</span>) : ("")}

                                    <button type="submit" disabled={!props.dirty && props.isSubmitting} className="btnLogin transition250">Connexion</button>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </Formik>
        </React.Fragment>
    );
};

export default Login;
