/**
 * Class Service.
 */
export default class AppService {
    /**
     * Get property value in object.
     *
     * @param obj
     * @param key
     */
    public static getProperty<T, K extends keyof T>(obj: T, key: K) {
        return obj[key];
    }

    /**
     * Get link params for application.
     */
    public static getLinksParams(): object[] {
        return [
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/site-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-coding', pClass: 'textMenu', value: 'Sites'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/demand-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-spam-alert', pClass: 'textMenu', value: 'Demandes'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/work-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-browser', pClass: 'textMenu', value: 'Tickets / tâches'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/datas-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-server', pClass: 'textMenu', value: 'Datas'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/datas-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-dashboard', pClass: 'textMenu', value: 'Référencement'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/datas-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-search', pClass: 'textMenu', value: 'Noms de domaine'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/datas-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-consulting', pClass: 'textMenu', value: 'Service aprés-vente'},
            {liClass: 'bxd3 bxt4 bxm12 element', linkTo: '/datas-lists', linkClass: 'transition250', divClass: 'structureElement', spanClass: 'icon icon-network', pClass: 'textMenu', value: 'Réseaux sociaux'},
        ];
    }
}


