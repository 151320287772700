import React, {useEffect, useState} from "react";
import {Table, Spinner} from 'react-bootstrap';
import WorkAPIs from "../../APIs/WorkAPIs";
import IWork from "../../interfaces/IWork";
import {AxiosResponse} from "axios";
import SiteService from "../../services/SiteService";
import HeaderList from "../list/HeaderList";
import RowsList from "../list/RowsList";
import WorkRowList from "./WorkRowList";
import Paginate from "../pagination/Paginate";
import "./Work.scss";

const WorkList: React.FC <{tab: string}> = props => {
    const [workList, setWorkList] = useState<IWork[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [perPage, setPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        WorkAPIs.getList(props.tab, page, perPage)
            .then((response: AxiosResponse) => {
                setLoading(false);
                setWorkList(response.data.list);
            })
    }, [props.tab, page, perPage]);

    return (
        <>
            {loading ?
                <div className="Spinner">
                    <Spinner animation="border"/>
                </div> :
                <div className="tableContent">
                    <Table id={props.tab} striped bordered hover size="sm" responsive>
                        <HeaderList listHeaders={SiteService.getHeaderList(props.tab)}/>
                        <RowsList rowComponent={WorkRowList} tab={props.tab} list={workList} setList={setWorkList}/>
                    </Table>
                    <Paginate page={page} setPage={setPage} perPage={perPage} countApi={`work/list/${props.tab}/count`}/>
                </div>
            }
        </>
    );
};

export default WorkList;
