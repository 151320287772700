/**
 * Class SiteService.
 */
import AppService from "./AppService";

class SiteService extends AppService {

    public SITE_TABS = [
        {key: 'inProduction', label: 'Site en production'},
        {key: 'deliveryPlanning', label: 'Planning livraison'},
        {key: 'SpecValidation', label: 'Validation CDC'},
        {key: 'toLogoDesign', label: 'A designer logo'},
        {key: 'toDesign', label: 'A designer'},
        {key: 'toIntegrate', label: 'A intégrer'},
        {key: 'toValidate', label: 'A valider'},
        {key: 'toDeliver', label: 'A livrer'},
        {key: 'toClose', label: 'A clôturer'},
    ];

    public SITE_LIST_HEADERS = {
        inProduction: ['Nom projet'],
        deliveryPlanning: ['Nom projet'],
        SpecValidation: ['Nom projet'],
        toLogoDesign: ['Nom projet'],
        toDesign: ['Nom projet'],
        toIntegrate: ['Nom projet'],
        toValidate: ['Nom projet'],
        toDeliver: ['Nom projet'],
        toClose: ['Nom projet'],
    };

    /**
     * Get header for specific tabs.
     *
     * @param datasTabs
     */
    public getHeaderList(datasTabs: any): [] {
        return SiteService.getProperty(this.SITE_LIST_HEADERS, datasTabs);
    }
}

export default new SiteService();
