import React, {useState} from "react";
import {Button} from "react-bootstrap";
import IDatas from "../../interfaces/IDatas";
import DatasService from "../../services/DatasService";
import AppService from '../../services/AppService';
import DatasCommentModal from "../modal/DatasCommentModal";
import ClientProfileModal from "../modal/ClientProfileModal";
import DatasAPIs from "../../APIs/DatasAPIs";

const DatasRowActionsList: React.FC<{index: number, datas: IDatas, list: IDatas[], setList: Function, tab: string, onMouseEnterFunction: any}> = props => {
    const [modalCommentShow, setModalCommentShow] = useState<boolean>(false);
    const [modalClientProfileShow, setModalClientProfileShow] = useState<boolean>(false);

    function getActionsButtonsParams(): any {
        return {
            addCommentary: {className:'transition250 button', size: 'sm', onClick: {func: setModalCommentShow, param: true}, value: 'Ajouter un commentaire'},
            clientProfile: {className:'transition250 button', size: 'sm', onClick: {func: setModalClientProfileShow, param: true}, value: 'Voir le profil du client'},
            previousStatus: {className:'transition250 button', size: 'sm', onClick: {func: handleStatusClick, param: 'previous'}, value: 'Statut précédent'},
            nextStatus: {className:'transition250 button', size: 'sm', onClick: {func: handleStatusClick, param: 'next'}, value: 'Statut suivant'},
            successStep: {className:'transition250 button', size: 'sm', onClick: null, value: 'Un pouce vert'},
            errorStep: {className:'transition250 button', size: 'sm', onClick: null, value: 'Un pouce rouge'},
        };
    }

    function getActionsButtons(value: any): any[] {
        let actionsButtons: any[] = [];
        const btnParams = AppService.getProperty(getActionsButtonsParams(), value);

        actionsButtons.push(
            <Button
                key={value}
                className={btnParams.className}
                size={btnParams.size}
                onClick={btnParams.onClick && (() => btnParams.onClick.func(btnParams.onClick.param))}
            >
                {btnParams.value}
            </Button>

        );

        return actionsButtons;
    }

    function handleStatusClick(statusType: string = 'next') {
        DatasAPIs.postNewStatus(props.datas.id, statusType)
            .then(res => {
                const newList: IDatas[] = props.list.filter((datas, index) => index !== props.index);
                props.setList(newList);
            });
    }

    return (
        <div className="actionContainer" onMouseEnter={props.onMouseEnterFunction}>
            {
                DatasService.getActionsPerTab(props.tab).map((value) => (
                    getActionsButtons(value)
                ))
            }

            <DatasCommentModal index={props.index} datas={props.datas} datasList={props.list} setDatasList={props.setList} show={modalCommentShow} onHide={() => setModalCommentShow(false)}/>
            <ClientProfileModal client={props.datas.produit && props.datas.produit.client} show={modalClientProfileShow} onHide={() => setModalClientProfileShow(false)}/>
        </div>
    );
};

export default DatasRowActionsList;
