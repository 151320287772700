import IUser from "../interfaces/IUser";

/**
 * UserStorage class.
 */
export default class UserStorage {
    private static readonly LOCAL_STORAGE_USER = 'user';

    /**
     * Store user in localStorage.
     *
     * @param user
     */
    public static storeAuthenticatedUser(user: IUser): void {
        localStorage.setItem(this.LOCAL_STORAGE_USER, JSON.stringify(user));
    }

    /**
     * Get token from localStorage.
     */
    public static getAuthenticatedUser(): IUser | null {
        const user = localStorage.getItem(this.LOCAL_STORAGE_USER);

        return null !== user ? JSON.parse(user) : null;
    }

    /**
     * Clear token from localStorage.
     */
    public static clear(): void {
        localStorage.removeItem(this.LOCAL_STORAGE_USER);
    }
}
