import React from "react";
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

const HomeLink: React.FC<{ liClass: string, linkTo: string, linkClass: string, divClass: string, spanClass: string, pClass: string, pValue: string }> = props => (
    <li className={props.liClass}>
        <Nav.Link as={Link} to={props.linkTo} className={props.linkClass}>
            <div className={props.divClass}>
                <span className={props.spanClass}></span>
                <p className={props.pClass}>{props.pValue}</p>
            </div>
        </Nav.Link>
    </li>
);

export default HomeLink;
