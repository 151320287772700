import React from 'react';
import {Link} from "react-router-dom";
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import LoginAPIs from "../../APIs/LoginAPIs";
import logo from '../../img/logo.svg';
import IUser from "../../interfaces/IUser";
import "./Header.scss";
import AppService from "../../services/AppService";

const Header: React.FC<{user: IUser | null}> = props => {
    return (
        <header className="header">
            <Navbar.Brand as={Link} className="logoContainer" to="/">
                <img src={logo} alt="logo alfred" className="logo"/>
            </Navbar.Brand>
            <div className="Connected">
                {props.user && <p className="nameConnect">{props.user.prenom} {props.user.nom}</p>}
                <Nav.Link as={Link} to="/logout" onClick={LoginAPIs.logout}>
                    <span className="icon icon-deconnexion"></span>
                </Nav.Link>
            </div>
            <div className="barSearch">
                <input type="text" className="inputText" placeholder="Recherche"/>
            </div>
            <Nav className="navContainer">
                <NavItem className="navStructure">
                    {
                        AppService.getLinksParams().map((params: any, index: number) => (
                            <Nav.Link key={index} as={Link} to={params.linkTo} className={params.linkClass}>{params.value}</Nav.Link>
                        ))
                    }
                </NavItem>
            </Nav>
        </header>
    );
};

export default Header;

