import Api from "../helpers/Api";
import TokenStorage from "../helpers/TokenStorage";
import GenericAPIs from "./GenericAPIs";

/**
 * DatasAPIs class.
 */
export default class DatasAPIs extends GenericAPIs {
    public static TYPE = 'datas';

    /**
     * Post new datas status.
     *
     * @param datasId
     * @param inputs
     */
    public static postDatasAction(datasId: number, inputs: any) {
        const data = {
            headers: TokenStorage.getAuthentication(),
        };

        return Api.post(`${this.TYPE}/${datasId}/add-datas-action`, inputs, data);
    }
}
