import React, {useEffect, useState} from "react";
import {Table, Spinner} from 'react-bootstrap';
import ISite from "../../interfaces/ISite";
import SiteAPIs from "../../APIs/SiteAPIs";
import {AxiosResponse} from "axios";
import SiteService from "../../services/SiteService";
import HeaderList from "../list/HeaderList";
import RowsList from "../list/RowsList";
import Paginate from "../pagination/Paginate";
import SiteRowList from "./SiteRowList";
import "./Site.scss";

const SiteList: React.FC <{tab: string}> = props => {
    const [siteList, setSiteList] = useState<ISite[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [perPage, setPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        SiteAPIs.getList(props.tab, page, perPage)
            .then((response: AxiosResponse) => {
                setLoading(false);
                setSiteList(response.data.list);
            })
    }, [props.tab, page, perPage]);

    return (
        <>
            {loading ?
                <div className="Spinner">
                    <Spinner animation="border"/>
                </div> :
                <div className="tableContent">
                    <Table id={props.tab} striped bordered hover size="sm" responsive>
                        <HeaderList listHeaders={SiteService.getHeaderList(props.tab)}/>
                        <RowsList rowComponent={SiteRowList} tab={props.tab} list={siteList} setList={setSiteList}/>
                    </Table>
                    <Paginate page={page} setPage={setPage} perPage={perPage} countApi={`site/list/${props.tab}/count`}/>
                </div>
            }
        </>
    );
};

export default SiteList;
