import React from "react";

const RowsList: React.FC<{ rowComponent: React.FC<any>, tab: string, list: any[], setList: Function }> = props => {
    return (
        <tbody>
        {
            props.list.map((item: any, index: number) => {
                const propsObject = {
                    tab: props.tab,
                    key: item.id,
                    index: index,
                    item: item,
                    list: props.list,
                    setList: props.setList,
                };

                return React.createElement(props.rowComponent, propsObject);
            })
        }
        </tbody>
    )
};

export default RowsList;
