import React, {useState} from "react";
import Interceptor from "../../helpers/Interceptor";
import TabsList from "./TabsList";

const Lists: React.FC<{ listComponent: React.FC<any>, setAppClassName: Function, type: string,  initialTab: string, tabs: { key: string; label: string; }[] }> = props => {
    Interceptor();
    props.setAppClassName('App noSectionMenu');

    const [tab, setTab] = useState<string>(props.initialTab);

    return (
        <div className="tableContainer">
            <div className="tableStructure">
                <TabsList type={props.type} tab={tab} setTab={setTab} tabs={props.tabs}/>
                {
                    React.createElement(props.listComponent, {tab: tab})
                }
            </div>
        </div>
    );
};

export default Lists;
