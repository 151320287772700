import Api from "../helpers/Api";
import TokenStorage from "../helpers/TokenStorage";

/**
 * APIs class.
 */
export default class GenericAPIs {
    public static TYPE = 'generic';

    /**
     * Get datas list.
     *
     * @param tab
     * @param page
     * @param perPage
     * @param type
     */
    public static getList(tab: string, page: number, perPage: number, type: string = this.TYPE) {
        const data = {
            headers: TokenStorage.getAuthentication(),
            params: {
                page: page,
                perPage: perPage,
            },
        };

        return Api.get(`${type}/list/${tab}`, data);
    }

    /**
     * Get datas list.
     *
     * @param tab
     * @param type
     */
    public static countList(tab: string, type: string = this.TYPE) {
        const data = TokenStorage.getAuthenticationHeader();

        return Api.get(`${type}/list/${tab}/count`, data);
    }

    /**
     * Post datas action.
     *
     * @param entityId
     * @param statusType
     */
    public static postNewStatus(entityId: number, statusType: string) {
        const data = {
            headers: TokenStorage.getAuthentication(),
        };

        return Api.post(`${this.TYPE}/${entityId}/${statusType}-status`, {}, data);
    }
}
