import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {AxiosResponse} from "axios";
import Api from "../../helpers/Api";
import TokenStorage from "../../helpers/TokenStorage";
import "./Paginate.scss";

const Paginate: React.FC<{ page: number, setPage: Function, perPage: number, countApi: string }> = props => {
    const [nbPage, setNbPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        Api.get(props.countApi, TokenStorage.getAuthenticationHeader())
            .then((response: AxiosResponse) => {
                const paginationRsp = response.data.pagination;
                const total = paginationRsp.total;
                const nbPage = Math.ceil(total / props.perPage);

                setTotal(total);
                setNbPage(nbPage);
            })
    }, [props.countApi, props.page, props.perPage]);

    const handlePageClick = (data: any) => {
        const selectedPage = data.selected + 1;

        props.setPage(selectedPage);
    };

    return (
        <>
            {
                1 < nbPage &&
                <div className="React-paginate">
                    <ReactPaginate
                        previousLabel={'← Précédent'}
                        nextLabel={'Suivant →'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        pageCount={nbPage}
                        forcePage={props.page - 1}
                        onPageChange={handlePageClick}
                    />
                </div>
            }
        </>
    );
};

export default Paginate;
