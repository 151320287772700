import React from "react";
import "./Home.scss";
import HomeLink from "./HomeLink";
import AppService from "../../services/AppService";

const Home: React.FC<{setAppClassName: Function}> = props => {
    props.setAppClassName('App');

    return (
        <nav className="homeContainerMenu containerSize">
            <ul className="homeMenu bxrow">
            {
                AppService.getLinksParams().map((params: any, index: number) => (
                    <HomeLink key={index} liClass={params.liClass} linkTo={params.linkTo} linkClass={params.linkClass} divClass={params.divClass} spanClass={params.spanClass} pClass={params.pClass} pValue={params.value}/>
                ))
            }
            </ul>
        </nav>
    );
};

export default Home;
