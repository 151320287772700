import TokenStorage from "./TokenStorage";
import Api from "./Api";
import {AxiosError, AxiosResponse} from "axios";

/**
 * System to refresh token and re-send request.
 */
export default () => {
    Api.interceptors.response.use( (response: AxiosResponse) => {
        // Return a successful response back to the calling service
        return response;
    }, (error: AxiosError) => {
        // Return any error which is not due to authentication back to the calling service
        if (error.response && error.response.status !== 401) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        // Logout user if token refresh didn't work or user is disabled
        if (error.config && '/api/token/refresh' === error.config.url) {

            TokenStorage.clear();
             window.location.href = '/login';

            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        // Try request again with new token
        return TokenStorage.refreshToken()
            .then((token: string) => {

                // New request with new token
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${token}`;

                return new Promise((resolve, reject) => {
                    Api.request(config).then((response: AxiosResponse) => {
                        resolve(response);
                    }).catch((error: AxiosError) => {
                        reject(error);
                    })
                });

            })
            .catch((error: AxiosError) => {
                Promise.reject(error);
            });
    });
}
