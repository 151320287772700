import React, {useEffect, useState} from "react";
import {Table, Spinner} from 'react-bootstrap';
import IDatas from "../../interfaces/IDatas";
import DatasAPIs from "../../APIs/DatasAPIs";
import {AxiosResponse} from "axios";
import DatasService from "../../services/DatasService";
import HeaderList from "../list/HeaderList";
import RowsList from "../list/RowsList";
import DatasRowList from "./DatasRowList";
import Paginate from "../pagination/Paginate";
import "./Datas.scss";

const DatasList: React.FC<{ tab: string }> = props => {
    const [datasList, setDatasList] = useState<IDatas[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [perPage, setPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    //const [tabTmp, setTabTmp] = useState<string>(props.tab);

    useEffect(() => {
        /*if (tabTmp !== props.tab) {
            setPage(1);
            setTabTmp(props.tab);
        }*/
        DatasAPIs.getList(props.tab, page, perPage)
            .then((response: AxiosResponse) => {
                setLoading(false);
                setDatasList(response.data.list);
            })
    }, [props.tab, page, perPage]);

    return (
        <>
            {loading ?
                <div className="Spinner">
                    <Spinner animation="border"/>
                </div> :
                <div className="tableContent">
                    <Table id={props.tab} striped bordered hover size="sm" responsive>
                        <HeaderList listHeaders={DatasService.getHeaderList(props.tab)}/>
                        <RowsList rowComponent={DatasRowList} tab={props.tab} list={datasList} setList={setDatasList}/>
                    </Table>
                    <Paginate page={page} setPage={setPage} perPage={perPage}
                              countApi={`datas/list/${props.tab}/count`}/>
                </div>
            }
        </>
    );
};

export default DatasList;
