import React from "react";
import IClient from "../../interfaces/IClient";
import * as moment from "moment";
import CustomModal from "./CustomModal";

function getBodyFragment(client: IClient) {
    return <>
        N° Oscar :{client.oscar}
        <br/>
        Client ID : {client.id} - Référence : {client.reference} - Nom du client : {client.name}
        <br/>
        Créé le {moment.default(client.created).format("DD/MM/YYYY")}
        <br/>
    </>;
}

const ClientProfileModal: React.FC<{ client: IClient, show: boolean, onHide: any }> = props => (
    <>
        <CustomModal
            show={props.show}
            onHide={props.onHide}
            title="Fiche client"
            body={getBodyFragment(props.client)}
            footer={null}
        />
    </>
);

export default ClientProfileModal;
