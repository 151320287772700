import {AxiosError, AxiosResponse} from 'axios';
import Api from "./Api";

/**
 * TokenStorage class.
 */
export default class TokenStorage {
    private static readonly LOCAL_STORAGE_TOKEN = 'token';
    private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

    /**
     * Check authentication.
     */
    public static isAuthenticated(): boolean {
        return null !== this.getToken();
    }

    /**
     * Get full authentication header.
     */
    public static getAuthenticationHeader(): { headers: object } {
        return  {
            headers: TokenStorage.getAuthentication(),
        };
    }

    /**
     * Get authorization header.
     */
    public static getAuthentication(): { Authorization: string } {
        return {
            'Authorization': 'Bearer ' + this.getToken()
        };
    }

    /**
     * Refresh token.
     */
    public static refreshToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            Api
                .post('token/refresh', {refresh_token: this.getRefreshToken()})
                .then((response: AxiosResponse) => {

                    this.storeToken(response.data.token);
                    this.storeRefreshToken(response.data.refresh_token);

                    resolve(response.data.token);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    /**
     * Store token in localStorage.
     *
     * @param token
     */
    public static storeToken(token: string): void {
        localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
    }

    /**
     * Refresh token in localStorage.
     *
     * @param refreshToken
     */
    public static storeRefreshToken(refreshToken: string): void {
        localStorage.setItem(this.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    }

    /**
     * Clear token from localStorage.
     */
    public static clear(): void {
        localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(this.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    /**
     * Get refresh token from localStorage.
     */
    public static getRefreshToken(): string | null {
        return localStorage.getItem(this.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    /**
     * Get token from localStorage.
     */
    public static getToken(): string | null {
        return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
    }
}
