/**
 * Class SiteService.
 */
import AppService from "./AppService";

class WorkService extends AppService {

    public WORK_TABS = [
        {key: 'toQualify', label: 'A qualifier'},
        {key: 'poorlyQualified', label: 'Mal qualifié'},
        {key: 'inProgress', label: 'En cours'},
        {key: 'toValidate', label: 'A valider'},
        {key: 'awaitingProd', label: 'En attente de montée en prod'},
        {key: 'recentlyCompleted', label: 'Términé récemment '},
    ];

    public WORK_LIST_HEADERS = {
        toQualify: ['Nom projet'],
        poorlyQualified: ['Nom projet'],
        inProgress: ['Nom projet'],
        toValidate: ['Nom projet'],
        awaitingProd: ['Nom projet'],
        recentlyCompleted: ['Nom projet'],
    };

    /**
     * Get header for specific tabs.
     *
     * @param datasTabs
     */
    public getHeaderList(datasTabs: any): [] {
        return WorkService.getProperty(this.WORK_LIST_HEADERS, datasTabs);
    }
}

export default new WorkService();
