import React from "react";
import "./DemandList.scss";
import Interceptor from "../../helpers/Interceptor";

const DemandList: React.FC<{ setAppClassName: Function }> = props => {
    Interceptor();
    props.setAppClassName('App noSectionMenu');

    return (
        <div id="demand">
            DEMANDS
        </div>
    );
};

export default DemandList;
