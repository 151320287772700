import React from "react";
import * as moment from "moment";
import IDatas from "../../interfaces/IDatas";
import IDatasAction from "../../interfaces/IDatasAction";
import IProduct from "../../interfaces/IProduct";
import DatasRowActionsList from "./DatasRowActionsList";
import DatasService from '../../services/DatasService';
import CssModifier from "../../helpers/CssModifier";

/**
 * Get formatted actions commentaries.
 *
 * @param actions
 */
function getActionsCommentaries(actions: IDatasAction[]) {
    if (0 !== actions.length) {
        return actions.map((action) => {
                return <p key={action.id}>
                    {moment.default(action.created).format("DD/MM/YYYY")}
                    {` - ${action.commentaire}`}
                </p>;
            }
        );
    }

    return '';
}

/**
 * Get formatted created at product with type.
 *
 * @param product
 */
function getFormattedProduct(product: IProduct) {
    return <>
        {product.type} <br/> {moment.default(product.created).format("DD/MM/YYYY")}
    </>;
}

const DatasRowList: React.FC<{ index: number, item: IDatas, list: IDatas[], setList: Function, tab: string }> = props => {
    let timer: any = null;

    const handleActionsClick = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
        const currentTarget: EventTarget = event.currentTarget;
        const firstChild: ChildNode | null = event.currentTarget.firstChild;

        if (CssModifier.hasClass(currentTarget, 'icon-arrow-bottom')) {
            CssModifier.removeClass(currentTarget, 'icon-arrow-bottom');
            CssModifier.addClass(currentTarget, 'icon-arrow-top');
            CssModifier.addClass(firstChild, 'active');
        } else {
            CssModifier.removeClass(currentTarget, 'icon-arrow-top');
            CssModifier.addClass(currentTarget, 'icon-arrow-bottom');
            CssModifier.removeClass(firstChild, 'active');
        }
    };

    const handleActionsMouseLeave = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
        const currentTarget: EventTarget = event.currentTarget;
        const firstChild: ChildNode | null = event.currentTarget.firstChild;

        timer = setTimeout(() => {
            CssModifier.removeClass(currentTarget, 'icon-arrow-top');
            CssModifier.addClass(currentTarget, 'icon-arrow-bottom');
            CssModifier.removeClass(firstChild, 'active');
        }, 2000);
    };

    const handleActionsMouseEnter = () => {
        if (null !== timer) {
            clearTimeout(timer);
        }
    };

    return (
        <tr>
            <td>{props.item.engagementDate && moment.default(props.item.engagementDate).format("DD/MM/YYYY")}</td>
            <td>{props.item.produit && props.item.produit.client.name}</td>
            <td>{props.item.type && props.item.type.name}</td>
            <td>{props.item.prestataire}</td>
            <td>{props.item.statut && props.item.statut.name}</td>
            <td>{props.item.produit && getFormattedProduct(props.item.produit)}</td>
            {'inError' !== props.tab && <td>{props.item.actions && getActionsCommentaries(props.item.actions)}</td>}
            {'toInstall' === props.tab && <td>Date de planification</td>}
            {'toVerify' === props.tab && <td>Date d'intégration</td>}
            {'finished' === props.tab && <td>Date de clôture</td>}
            <td>{props.item.produit && props.item.produit.chefdeprojet && DatasService.getUserFullName(props.item.produit.chefdeprojet)}</td>
            {'toObtain' !== props.tab && <td>Opérateur</td>}
            {'inError' === props.tab && <td>N° de tâche</td>}
            {'inError' === props.tab && <td>{props.item.actions && getActionsCommentaries(props.item.actions)}</td>}
            {'inError' === props.tab && <td>Attribué à</td>}
            <td className="selectAction icon-arrow-bottom" onClick={handleActionsClick} onMouseLeave={handleActionsMouseLeave}>
                <DatasRowActionsList index={props.index} datas={props.item} list={props.list} setList={props.setList} tab={props.tab} onMouseEnterFunction={handleActionsMouseEnter}/>
            </td>
        </tr>
    );
};

export default DatasRowList;
