import React  from "react";

const HeaderList : React.FC <{listHeaders: []}> = props => (
    <thead>
    <tr className="transition250">
        {
            props.listHeaders.map((value:string) => (
                <th key={value}>{value}</th>
            ))
        }
    </tr>
    </thead>
);

export default HeaderList;
