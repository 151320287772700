import Api from "../helpers/Api";
import TokenStorage from "../helpers/TokenStorage";

/**
 * UserAPIs class.
 */
export default class UserAPIs {
    /**
     * Get authenticated user.
     */
    public static getAuthenticatedUser() {
        const data = {
            headers: TokenStorage.getAuthentication(),
        };

        return Api.get(`user/authenticated`, data);
    }
}
