import React from "react";
import ISite from "../../interfaces/ISite";

const SiteRowList: React.FC<{ index: number, item: ISite, list: ISite[], setList: Function, tab: string }> = props => {
    return (
        <tr>
            <td>Project name</td>
        </tr>
    );
};

export default SiteRowList;
