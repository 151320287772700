import React from "react";
import IWork from "../../interfaces/IWork";

const WorkRowList: React.FC<{ index: number, item: IWork, list: IWork[], setList: Function, tab: string }> = props => {
    return (
        <tr>
            <td>Project name</td>
        </tr>
    );
};

export default WorkRowList;
