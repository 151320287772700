import React  from "react";
import {Tabs, Tab} from 'react-bootstrap';

const TabsList: React.FC <{type: string, tab: string, setTab: Function, tabs:  {key: string; label: string; }[]}> = props => (

        <Tabs className="selectionContainer" id={`controlled-${props.type}-tabs`} activeKey={props.tab} onSelect={(tab: string) => props.setTab(tab)}>
            {
                props.tabs.map(({key, label}) => (
                    <Tab tabClassName="element transition250" key={key} eventKey={key} title={label} />
                ))
            }
        </Tabs>
);

export default TabsList;
